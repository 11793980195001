<script>
import feather from "feather-icons";
import projects from "../../data/projects";

export default {
	components: {},
	data: () => {
		return {
			projects,
			projectsHeading: "Projects Portfolio",
		};
	},
	mounted() {
		feather.replace();
	},
	props: ["project"],
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div class="text-center">
			<p
				class="mb-2 text-2xl font-semibold font-general-semibold sm:text-5xl text-ternary-dark dark:text-ternary-light">
				{{ projectsHeading }}
			</p>
		</div>
		<!-- Projects grid -->
		<div class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-3 sm:gap-10">
			<router-link to="/projects/game2D"
				aria-label="Portfolio">
				<div
					class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
					<img class="rounded-t-lg" src="@/assets/images/safari-game.jpg" alt="" />
					<div class="p-5">
							<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
								Game 2D
							</h5>
					</div>
				</div>
			</router-link>
			<router-link to="/projects/oldportfolio"
				aria-label="Portfolio">
				<div
					class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
					<img class="rounded-t-lg" src="@/assets/images/portfolio.jpg" alt="" />
					<div class="p-5">
							<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
								My old portfolio
							</h5>
					</div>
				</div>
			</router-link>
			<router-link to="/projects/massageweb"
				aria-label="Portfolio">
				<div
					class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
					<img class="rounded-t-lg" src="@/assets/images/massage-web.jpg" alt="" />
					<div class="p-5">
							<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
								Massage website
							</h5>
					</div>
				</div>
			</router-link>
		</div>
	</section>
</template>

<style scoped></style>
